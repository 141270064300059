import * as React from 'react';

const defaultValues = {
  ctaIndex: 0,
  setCtaIndex: () => {}
};

export const FixedBtnContext = React.createContext(defaultValues);

export const FixedBtnProvider = ({ children }) => {
  const [ctaIndex, setCtaIndex] = React.useState(defaultValues.ctaIndex);

  return (
    <FixedBtnContext.Provider
      value={{
        ...defaultValues,
        ctaIndex,
        setCtaIndex
      }}
    >
      {children}
    </FixedBtnContext.Provider>
  );
};
