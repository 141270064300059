import axios, { AxiosInstance } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const url = `https://${process.env.GATSBY_ENV === 'prod' ? '' : 'dev-'}platform.konfio.mx/core/`;

const makeAxiosClient = (baseURL: string) => {
  return axios.create({
    baseURL,
    responseType: 'json'
  });
};

const liveClient = makeAxiosClient(url);
const mockClient = makeAxiosClient(url);

export const mockInstance = new AxiosMockAdapter(mockClient, { delayResponse: 300 });

const getClient = (liveClient: AxiosInstance, mockClient: AxiosInstance) => {
  return process.env.GATSBY_ENV === 'l' ? mockClient : liveClient;
};

export const envClient = getClient(liveClient, mockClient);
