/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { StoreProvider } from './src/context/store-context';
import { VersionProvider } from './src/context/version-context';
import { FixedBtnProvider } from './src/context/fixed-btn-context';
import { KompThemeProvider } from '@konfio/komp-library';
import { NavigatorProvider } from './src/context/navigatorContext';

import { CriticalCSSProvider, StyleRegistry } from 'react-critical-css';
import './src/styles/global-compressed.css';

const styleRegistry = new StyleRegistry();

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash && typeof window !== 'undefined') {
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0);
  }
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (typeof window !== 'undefined') {
    window.history.scrollRestoration = 'manual';
    const currentPosition = getSavedScrollPosition(location) || [0, 0];

    setTimeout(() => {
      if (location.hash) {
        const item = document.querySelector(`${location.hash}`);
        item.scrollIntoView && item.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo({
          top: currentPosition[1],
          left: currentPosition[0],
          behavior: 'instant'
        });
      }
    }, 0);
  }
  return false;
};

export const wrapRootElement = ({ element }) => (
  <CriticalCSSProvider registry={styleRegistry}>
    <NavigatorProvider>
      <KompThemeProvider>
        <FixedBtnProvider>
          <VersionProvider>
            <StoreProvider>{element}</StoreProvider>
          </VersionProvider>
        </FixedBtnProvider>
      </KompThemeProvider>
    </NavigatorProvider>
  </CriticalCSSProvider>
);
