import React, { useState } from 'react';
type PageType = 'product' | 'performance';

type BaseOptions = {
  pageType: PageType;
  pageView: string;
  cta: {
    text: string;
    href: string;
  };
  login: {
    text: string;
    href: string;
  };
};

type NavigatorOptions = BaseOptions & {
  fixedNavbar: boolean;
  showFixedCta: boolean;
};

type NavigatorContextConfig = NavigatorOptions & {
  setConfig: (options: NavigatorOptions) => void;
  setShowFixedCta: (status: boolean) => void;
  setFixedNavbar: (status: boolean) => void;
  setBaseConfig: (options: BaseOptions) => void;
};

const defaultValues: NavigatorContextConfig = {
  fixedNavbar: false,
  showFixedCta: true,
  pageType: 'product',
  cta: {
    text: 'Crece con Konfío',
    href: '/mi/registro'
  },
  login: {
    text: 'Ingresar',
    href: '/mi/login'
  },
  pageView: '',
  setConfig: () => {},
  setShowFixedCta: () => {},
  setFixedNavbar: () => {},
  setBaseConfig: () => {}
};

export const NavigatorContext = React.createContext<NavigatorContextConfig>(defaultValues);

export const NavigatorProvider: React.FC = ({ children }) => {
  const [showFixedCta, setShowFixedCta] = useState(defaultValues.showFixedCta);
  const [pageType, setPageType] = useState(defaultValues.pageType);
  const [pageView, setPageView] = useState(defaultValues.pageView);
  const [fixedNavbar, setFixedNavbar] = useState(defaultValues.fixedNavbar);
  const [cta, setCta] = useState(defaultValues.cta);
  const [login, setLogin] = useState(defaultValues.login);

  const setConfig = (nextConfig: NavigatorOptions) => {
    setShowFixedCta(nextConfig.showFixedCta);
    setPageType(nextConfig.pageType);
    setPageView(nextConfig.pageView);
  };

  const setBaseConfig = (options: BaseOptions) => {
    setPageType(options.pageType);
    setPageView(options.pageView);
    setCta({ text: options.cta.text, href: options.cta.href });
    setLogin({ text: options.login.text, href: options.login.href });
  };

  return (
    <NavigatorContext.Provider
      value={{
        cta,
        setBaseConfig,
        showFixedCta,
        fixedNavbar,
        pageType,
        pageView,
        setConfig,
        setShowFixedCta,
        setFixedNavbar,
        login
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};
