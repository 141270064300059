exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acompanamiento-seguridad-konfio-js": () => import("./../../../src/pages/acompanamiento/seguridad-konfio.js" /* webpackChunkName: "component---src-pages-acompanamiento-seguridad-konfio-js" */),
  "component---src-pages-alianzas-credit-brokers-js": () => import("./../../../src/pages/alianzas/credit-brokers.js" /* webpackChunkName: "component---src-pages-alianzas-credit-brokers-js" */),
  "component---src-pages-alianzas-creditaria-js": () => import("./../../../src/pages/alianzas/creditaria.js" /* webpackChunkName: "component---src-pages-alianzas-creditaria-js" */),
  "component---src-pages-alianzas-easy-homes-js": () => import("./../../../src/pages/alianzas/easy-homes.js" /* webpackChunkName: "component---src-pages-alianzas-easy-homes-js" */),
  "component---src-pages-alianzas-index-js": () => import("./../../../src/pages/alianzas/index.js" /* webpackChunkName: "component---src-pages-alianzas-index-js" */),
  "component---src-pages-alianzas-socases-js": () => import("./../../../src/pages/alianzas/socases.js" /* webpackChunkName: "component---src-pages-alianzas-socases-js" */),
  "component---src-pages-centro-de-ayuda-index-js": () => import("./../../../src/pages/centro-de-ayuda/index.js" /* webpackChunkName: "component---src-pages-centro-de-ayuda-index-js" */),
  "component---src-pages-conocenos-que-es-konfio-js": () => import("./../../../src/pages/conocenos/que-es-konfio.js" /* webpackChunkName: "component---src-pages-conocenos-que-es-konfio-js" */),
  "component---src-pages-conocenos-sostenibilidad-impacto-js": () => import("./../../../src/pages/conocenos/sostenibilidad-impacto.js" /* webpackChunkName: "component---src-pages-conocenos-sostenibilidad-impacto-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-credito-credito-para-emprendedores-js": () => import("./../../../src/pages/credito/credito-para-emprendedores.js" /* webpackChunkName: "component---src-pages-credito-credito-para-emprendedores-js" */),
  "component---src-pages-credito-credito-pyme-js": () => import("./../../../src/pages/credito/credito-pyme.js" /* webpackChunkName: "component---src-pages-credito-credito-pyme-js" */),
  "component---src-pages-directorio-empresas-pymes-index-js": () => import("./../../../src/pages/directorio-empresas-pymes/index.js" /* webpackChunkName: "component---src-pages-directorio-empresas-pymes-index-js" */),
  "component---src-pages-efos-y-edos-buscador-js": () => import("./../../../src/pages/efos-y-edos/buscador.js" /* webpackChunkName: "component---src-pages-efos-y-edos-buscador-js" */),
  "component---src-pages-efos-y-edos-index-js": () => import("./../../../src/pages/efos-y-edos/index.js" /* webpackChunkName: "component---src-pages-efos-y-edos-index-js" */),
  "component---src-pages-growth-control-de-gastos-js": () => import("./../../../src/pages/growth/control-de-gastos.js" /* webpackChunkName: "component---src-pages-growth-control-de-gastos-js" */),
  "component---src-pages-growth-oferta-de-credito-js": () => import("./../../../src/pages/growth/oferta-de-credito.js" /* webpackChunkName: "component---src-pages-growth-oferta-de-credito-js" */),
  "component---src-pages-growth-safe-redirect-js": () => import("./../../../src/pages/growth/safe-redirect.js" /* webpackChunkName: "component---src-pages-growth-safe-redirect-js" */),
  "component---src-pages-growth-senorpago-js": () => import("./../../../src/pages/growth/senorpago.js" /* webpackChunkName: "component---src-pages-growth-senorpago-js" */),
  "component---src-pages-growth-spei-js": () => import("./../../../src/pages/growth/spei.js" /* webpackChunkName: "component---src-pages-growth-spei-js" */),
  "component---src-pages-growth-tarjeta-empresarial-js": () => import("./../../../src/pages/growth/tarjeta-empresarial.js" /* webpackChunkName: "component---src-pages-growth-tarjeta-empresarial-js" */),
  "component---src-pages-growth-ventajas-js": () => import("./../../../src/pages/growth/ventajas.js" /* webpackChunkName: "component---src-pages-growth-ventajas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pagos-centro-migracion-srpago-konfio-index-js": () => import("./../../../src/pages/pagos/centro-migracion-srpago-konfio/index.js" /* webpackChunkName: "component---src-pages-pagos-centro-migracion-srpago-konfio-index-js" */),
  "component---src-pages-pagos-promociones-index-js": () => import("./../../../src/pages/pagos/promociones/index.js" /* webpackChunkName: "component---src-pages-pagos-promociones-index-js" */),
  "component---src-pages-pagos-senorpago-index-js": () => import("./../../../src/pages/pagos/senorpago/index.js" /* webpackChunkName: "component---src-pages-pagos-senorpago-index-js" */),
  "component---src-pages-politicas-internas-index-js": () => import("./../../../src/pages/politicas-internas/index.js" /* webpackChunkName: "component---src-pages-politicas-internas-index-js" */),
  "component---src-pages-tarjetas-control-de-gastos-index-js": () => import("./../../../src/pages/tarjetas/control-de-gastos/index.js" /* webpackChunkName: "component---src-pages-tarjetas-control-de-gastos-index-js" */),
  "component---src-pages-tarjetas-pago-de-servicios-index-js": () => import("./../../../src/pages/tarjetas/pago-de-servicios/index.js" /* webpackChunkName: "component---src-pages-tarjetas-pago-de-servicios-index-js" */),
  "component---src-pages-tarjetas-pagos-de-impuestos-index-js": () => import("./../../../src/pages/tarjetas/pagos-de-impuestos/index.js" /* webpackChunkName: "component---src-pages-tarjetas-pagos-de-impuestos-index-js" */),
  "component---src-pages-tarjetas-promociones-index-js": () => import("./../../../src/pages/tarjetas/promociones/index.js" /* webpackChunkName: "component---src-pages-tarjetas-promociones-index-js" */),
  "component---src-pages-tarjetas-tarjeta-de-credito-visa-konfio-diferir-pagos-index-js": () => import("./../../../src/pages/tarjetas/tarjeta-de-credito-visa-konfio/diferir-pagos/index.js" /* webpackChunkName: "component---src-pages-tarjetas-tarjeta-de-credito-visa-konfio-diferir-pagos-index-js" */),
  "component---src-pages-tarjetas-tarjeta-de-credito-visa-konfio-programa-de-referidos-index-js": () => import("./../../../src/pages/tarjetas/tarjeta-de-credito-visa-konfio/programa-de-referidos/index.js" /* webpackChunkName: "component---src-pages-tarjetas-tarjeta-de-credito-visa-konfio-programa-de-referidos-index-js" */),
  "component---src-pages-tarjetas-transferencias-con-tarjeta-de-credito-index-js": () => import("./../../../src/pages/tarjetas/transferencias-con-tarjeta-de-credito/index.js" /* webpackChunkName: "component---src-pages-tarjetas-transferencias-con-tarjeta-de-credito-index-js" */),
  "component---src-pages-vacantes-index-js": () => import("./../../../src/pages/vacantes/index.js" /* webpackChunkName: "component---src-pages-vacantes-index-js" */),
  "component---src-templates-document-template-js": () => import("./../../../src/templates/DocumentTemplate.js" /* webpackChunkName: "component---src-templates-document-template-js" */),
  "component---src-templates-dynamic-cards-landing-js": () => import("./../../../src/templates/DynamicCardsLanding.js" /* webpackChunkName: "component---src-templates-dynamic-cards-landing-js" */),
  "component---src-templates-dynamic-credit-landing-js": () => import("./../../../src/templates/DynamicCreditLanding.js" /* webpackChunkName: "component---src-templates-dynamic-credit-landing-js" */),
  "component---src-templates-dynamic-global-landing-js": () => import("./../../../src/templates/DynamicGlobalLanding.js" /* webpackChunkName: "component---src-templates-dynamic-global-landing-js" */),
  "component---src-templates-dynamic-home-js": () => import("./../../../src/templates/DynamicHome.js" /* webpackChunkName: "component---src-templates-dynamic-home-js" */),
  "component---src-templates-dynamic-industry-landing-js": () => import("./../../../src/templates/DynamicIndustryLanding.js" /* webpackChunkName: "component---src-templates-dynamic-industry-landing-js" */),
  "component---src-templates-dynamic-legal-landing-js": () => import("./../../../src/templates/DynamicLegalLanding.js" /* webpackChunkName: "component---src-templates-dynamic-legal-landing-js" */),
  "component---src-templates-dynamic-payments-landing-js": () => import("./../../../src/templates/DynamicPaymentsLanding.js" /* webpackChunkName: "component---src-templates-dynamic-payments-landing-js" */),
  "component---src-templates-faq-payments-template-js": () => import("./../../../src/templates/FaqPaymentsTemplate.js" /* webpackChunkName: "component---src-templates-faq-payments-template-js" */),
  "component---src-templates-help-center-template-js": () => import("./../../../src/templates/HelpCenterTemplate.js" /* webpackChunkName: "component---src-templates-help-center-template-js" */),
  "component---src-templates-lead-form-template-js": () => import("./../../../src/templates/LeadFormTemplate.js" /* webpackChunkName: "component---src-templates-lead-form-template-js" */),
  "component---src-templates-mkt-page-template-js": () => import("./../../../src/templates/MktPageTemplate.js" /* webpackChunkName: "component---src-templates-mkt-page-template-js" */),
  "component---src-templates-promotion-page-template-js": () => import("./../../../src/templates/PromotionPageTemplate.js" /* webpackChunkName: "component---src-templates-promotion-page-template-js" */),
  "component---src-templates-redirect-template-js": () => import("./../../../src/templates/RedirectTemplate.js" /* webpackChunkName: "component---src-templates-redirect-template-js" */),
  "component---src-templates-topic-page-template-tsx": () => import("./../../../src/templates/TopicPageTemplate.tsx" /* webpackChunkName: "component---src-templates-topic-page-template-tsx" */)
}

