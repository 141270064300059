import * as React from 'react';

const defaultValues = {
  addVersionParams: () => {},
  getCurrentVersionFields: nodes => nodes,
  getCurrentVersionPage: nodes => nodes,
  setVersion: () => {},
  getCurrentVersion: () => 1,
  version: 1
};

const isBrowser = typeof window !== `undefined`;

export const VersionContext = React.createContext(defaultValues);

export const VersionProvider = ({ children }) => {
  const [version, setVersion] = React.useState(1);

  React.useLayoutEffect(() => {
    setVersion(getCurrentVersion());
  }, [setVersion]);

  const getCurrentVersionFields = (nodes, getPivot) => {
    const currentNodes = nodes.filter(
      node => node.publishedAt !== null || node.versionNumber === version
    );

    const result = [];

    while (currentNodes.length > 0) {
      const node = { ...currentNodes.shift() };

      const searchRepeated = e => getPivot(e) === getPivot(node);

      const sourceRepeated = currentNodes.some(searchRepeated);
      const resultRepeated = result.some(searchRepeated);

      if ((!sourceRepeated || node.versionNumber === version) && !resultRepeated) {
        result.push(node);
      }
    }

    return result;
  };

  const getCurrentVersionPage = nodes => {
    return [nodes[version - 1] || nodes[0]];
  };

  const addVersionParams = (url, currentVersion) => {
    if (version > 1 && version === currentVersion) {
      return url + '?v=' + version;
    }
    return url;
  };

  const getCurrentVersion = () => {
    if (isBrowser) {
      const currentParams = new URL(document.location).searchParams;
      return Number(currentParams.get('v') || 1);
    }

    return 1;
  };

  return (
    <VersionContext.Provider
      value={{
        ...defaultValues,
        addVersionParams,
        getCurrentVersionFields,
        getCurrentVersionPage,
        setVersion,
        getCurrentVersion,
        version
      }}
    >
      {children}
    </VersionContext.Provider>
  );
};
